import { useQuery } from "@tanstack/react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { RestService } from "../../services/Rest.service";

function LogoutPage() {
  // TODO: logout logic (eg token/session invalidation)

  const { data, error, isLoading }: any = useQuery(
    ["casts"],
    () => RestService.post("/oauth/logout"),
    {
      enabled: true,
    }
  );

  if (error) {
    throw error;
  }

  if (isLoading || error) {
    return (
      <div className="h-100 flex flex-col items-center justify-center absolute inset-0">
        Loading
      </div>
    );
  } else {
    return <Navigate to="/login"></Navigate>;
  }
}

export default LogoutPage;
