import * as React from "react";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import MainBodyLayoutComponent from "../../components/MainBodyLayoutComponent/MainBodyLayoutComponent";
import NavBarComponent from "../../components/NavBarComponent/NavBarComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import Modal from "react-modal";

function CheckListModal(props: { isOpen: boolean }) {}

function CastEditor() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openChecklistModal = () => {
    setIsOpen(true);
  };
  const closeChecklistModal = () => {
    setIsOpen(false);
  };

  const checkList = [
    {
      title: "Supply your Cast Logo",
      instructions:
        "Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here.",
      link: "https://www.google.com",
    },
    {
      title: "Add content to your Cast Editor",
      instructions:
        "Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here.",
      link: "https://www.google.com",
    },
    {
      title: "Add your other Admins",
      instructions:
        "Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here.",
      link: "https://www.google.com",
    },
    {
      title: "Add your Users",
      instructions:
        "Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here.",
      link: "https://www.google.com",
    },
    {
      title: "Supply your Domain",
      instructions:
        "Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here. Instructions here.",
      link: "https://www.google.com",
    },
  ];

  return (
    <div>
      <NavBarComponent></NavBarComponent>

      {/* <TitleHeaderComponent></TitleHeaderComponent> */}

      <MainBodyLayoutComponent
        before={
          <div className="flex justify-between">
            <h2 className="form-heading">
              <i className="material-icons">palette</i>
              Cast Editor
            </h2>

            <div className="flex items-center justify-center">
              <button className="btn bg-transparent text-gray-400">
                <Icon name="remove_red_eye"></Icon>
                View Learners Site
              </button>

              <button
                onClick={openChecklistModal}
                className="bg-yellow-200 text-yellow-600 text-center rounded p-1 mx-2"
              >
                <Icon name="warning"></Icon> 1 of 5 steps done
              </button>

              <button className="btn btn-icon bg-transparent text-gray-500 mx-2">
                <Icon name="add_to_photos"></Icon>
              </button>

              <button className="btn btn-icon bg-transparent text-gray-500 mx-2">
                <Icon name="settings"></Icon>
              </button>
            </div>
          </div>
        }
      ></MainBodyLayoutComponent>

      <Modal
        className=" w-1/3 mx-auto my-20   p-5 bg-white border rounded-xl"
        isOpen={modalIsOpen}
        onRequestClose={closeChecklistModal}
      >
        <h2 className="text-2xl font-bold flex justify-between mb-10">
          <span>Cast Preparation Checklist</span>

          <button
            onClick={closeChecklistModal}
            className="btn btn-icon bg-transparent text-gray-500 mx-2"
          >
            <Icon name="close"></Icon>
          </button>
        </h2>

        <ul>
          {checkList.map((item) => {
            return (
              <li className="flex border-b border-gray-300 py-4">
                <span className="text-success-500 mr-2">
                  <Icon name="check_circle"></Icon>
                </span>

                <div>
                  <h3 className="text-xl font-bold">{item.title}</h3>

                  <p className="text-gray-500">{item.instructions}</p>

                  <a
                    href={item.link}
                    target="_blank"
                    className="text-secondary-600 underline"
                  >
                    Useful Link here
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </Modal>
    </div>
  );
}

export default CastEditor;
