import { buriCastRequest, buriPassRequest } from "./Rest.service";
export interface Cast {
  title: string;
  description: string;
  clientId: string;
  groupId: string;
  id?: string;
}
export interface Screen {
  castId: string;
  screen: any;
}
export interface Block {
  castId: string;
  screenId: string;
  block: any;
}

export interface Group {
  name: string;
  parentId: string;
  roleIds?: string[];
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  groupIds: any;
}

export interface Role {
  name: any;
  description: any;
  permissionNames: any;
}
export interface Roles {
  groupUuid: any;
  applicationUuid: any;
  roles: any[];
}
export interface CastId {
  castId: string;
}
export const createCast = async (cast: Cast, compactName: string) => {
  const res = await buriCastRequest.post("/casts", {
    cast: {
      title: cast.title,
      description: cast.description,
      properties: {
        auth: {
          client_id: cast.clientId,
          url: `${process.env.REACT_APP_PASS_URI}/oauth/authorize?group_id=${cast.groupId}`,
        },
        default_domain: {
          dashboard: `${compactName}.castdashboard.com`,
          learner: `${compactName}.castlms.com`
        },
        home_screen: "learn",
        humanize: {
          type: {
            buribook: "Buribook",
            "buribook-with-activity": "Buribook",
            package: "Course",
            pdf: "PDF",
            video: "Video",
            "video-with-activity": "Video",
          },
        },
        default_group_id: cast.groupId,
        initial_block_id: null,
        cast_icon: {
          web: {
            favicon: "",
            nav: process.env.REACT_APP_CLIENT_LOGO,
          },
        },
      },
    },
  });
  return res.data;
};

export const updateCast = async (cast: any) => {
  const res = await buriCastRequest.put(`/casts/${cast.id}`, { cast });
  return res.data;
};

export const createScreen = async (screen: Screen) => {
  const res = await buriCastRequest.post(`/casts/${screen.castId}/screens`, {
    screen: screen.screen,
  });
  return res.data;
};

export const createBlock = async (block: Block) => {
  const res = await buriCastRequest.post(
    `/casts/${block.castId}/screens/${block.screenId}/blocks`,
    {
      block: block.block,
    }
  );
  return res.data;
};

export const createGroup = async (group: Group) => {
  const res = await buriPassRequest.post("/groups", {
    group: {
      name: group.name,
      description: "",
      parent_id: group.parentId,
      role_ids: group.roleIds,
    },
  });
  return res.data;
};

export const listUsers = async (email: string) => {
  const res = await buriPassRequest.get("/users", {
    params: { emails: [email] },
  });
  return res.data;
};

export const updateUser = async (
  uuid: string,
  groupIds: string[],
  parentGroupId: string,
  adminGroupId: string
) => {
  groupIds.push(parentGroupId);
  groupIds.push(adminGroupId);
  const res = await buriPassRequest.patch(`/users/${uuid}`, {
    user: {
      group_ids: groupIds,
    },
  });
  return res.data;
};

export const createUser = async (user: User) => {
  const res = await buriPassRequest.post("/users", {
    user: {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      password: user.password,
      group_ids: user.groupIds,
      confirmed_at: new Date().toISOString(),
    },
  });
  return res.data;
};
export const createRole = async (_role: Role, appId: any) => {
  const res = await buriPassRequest.post("/roles", {
    role: {
      name: _role.name,
      description: _role.description,
      application_id: appId,
      permissions_attributes: _role.permissionNames.map((name: any) => ({
        name,
      })),
      tag_list: ["readonly"],
    },
  });
  return res.data;
};
export const createNewApplication = async (
  clientName: string,
  addDomain?: Array<string>
) => {
  const redirectTemplate = [
    "https://**********.cast.buri.dev/auth/new",
    "https://**********.castlms.com/auth/new",
    "http://**********.localhost:4200/auth/new",
    "https://**********.castdashboard.com/auth/callback",
    "https://**********.testing.castdashboard.com/auth/callback",
    "https://**********.staging.dashboard.buri.dev/auth/callback",
    "io.buri.android://oauth2Callback",
    "io.buri.ios://oauth2Callback",
  ];
  if (addDomain && addDomain.length > 0) {
    redirectTemplate.push(
      ...addDomain.map((domain: string) => `${domain}/auth/new`)
    );
  }
  const compactClientName = clientName.split(" ").join("").toLowerCase();
  const res = await buriPassRequest.post("/oauth/applications", {
    application: {
      name: clientName,
      redirect_uri: redirectTemplate
        .map((item) => item.replace("**********", compactClientName))
        .join("\n"),
      scopes: "openid profile email",
      confidential: false,
      default_group: {
        name: clientName,
      },
    },
  });
  return res.data;
};
export const createAndVerifyCastDomains = (
  id: CastId,
  compactName: string,
  addDomain?: string
) => {
  let urls = [`${compactName}.castlms.com`, `${compactName}.cast.buri.dev`];

  if (addDomain && addDomain.length > 0) {
    let domainName: any = addDomain.split("/").pop();
    console.log(`Domain Name: ${domainName}`);
    urls.push(domainName);
  }
  urls.forEach(async (url) => {
    let domain = await buriCastRequest.post(`/casts/${id.castId}/domains`, {
      domain: {
        name: url,
      },
    });
    await buriCastRequest.patch(
      `/casts/${id.castId}/domains/${domain.data.id}`,
      {}
    );
  });
};
export const createBatchGroupRolesAndPermission = (_roles: Roles) => {
  _roles.roles = _roles.roles || [];
  _roles.roles.forEach(async (role: Role) => {
    await buriPassRequest.post(`/roles`, {
      role: {
        name: role.name,
        description: role.description,
        group_ids: [_roles.groupUuid],
        application_id: _roles.applicationUuid,
        permissions_attributes: role.permissionNames.map((name: any) => ({
          name,
        })),
        tag_list: ["readonly"],
      },
    });
  });
};
export const generatePassword = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};
