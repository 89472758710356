import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { RestService } from "../../services/Rest.service";
import { CLIENT_ID } from "../LoginPage/LoginPage";
import { Navigate } from "react-router-dom";

function AuthCallback() {
  let url = new URL(window.location.href);

  const code = url.searchParams.get("code");

  const { data, isLoading, isError, error } = useQuery(
    ["issues", code],
    () =>
      RestService.post("/oauth", {
        code,
        redirect_uri: `${window.location.origin}/auth/callback`,
        platform: "web",
        client_id: CLIENT_ID,
      }),
    {
      enabled: !!code,
      retry: false,
    }
  );

  if (isError) {
    throw error;
  }

  if (!isLoading && !isError && data) {
    return <Navigate to="/"></Navigate>;
  }

  return (
    <div className="h-100 flex flex-col items-center justify-center absolute inset-0">
      Loading
    </div>
  );
}

export default AuthCallback;
