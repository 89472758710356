import * as React from "react";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import LoginBanner from "../../assets/login-banner.png";
import Icon from "../../components/Icon/Icon";
import MainBodyLayoutComponent from "../../components/MainBodyLayoutComponent/MainBodyLayoutComponent";
import NavBarComponent from "../../components/NavBarComponent/NavBarComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import {
  createAndVerifyCastDomains,
  createBlock,
  createCast,
  createGroup,
  createNewApplication,
  createRole,
  createScreen,
  createUser,
  generatePassword,
  listUsers,
  updateCast,
  updateUser,
} from "../../services/Request.service";
import { RestService } from "../../services/Rest.service";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useErrorHandler } from "react-error-boundary";

const createModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "80vh",
    width: "50vw",
    maxWidth: "50vh",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
const actionModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "20vh",
    width: "50vw",
    maxWidth: "50vh",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};
function CastListPage() {
  let navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [newCastName, setNewCastName] = useState("");
  const [customUri, setCustomUri] = useState("");
  const [newCastImageUri, setNewCastImageUri] = useState("");

  const {
    data: casts,
    error,
    isLoading,
  }: any = useQuery(
    ["casts"],
    () => RestService.get("/services/buricast/v2.1/casts"),
    {
      select: (data) => data.data,
      retry: false,
    }
  );
  useErrorHandler(error);

  const now = new Date(),
    year = now.getFullYear(),
    month = now.getMonth();
  const activeUsersQueries: any = useQueries({
    queries: (casts || []).map((cast: any) => {
      return {
        queryKey: ["activeUsers", cast.id],
        queryFn: () =>
          RestService.get("/services/buripass/users", {
            params: {
              limit: 0,
              last_sign_in_at_from: new Date(year, month, 1).toISOString(),
              last_sign_in_at_to: new Date(year, month + 1, 1).toISOString(),
              group_ids: cast.properties.default_group_id,
            },
          }),
        select: (data: any) => data.data,
      };
    }),
  });

  const openActionModal = () => {
    setActionModal(true);
  };
  const closeActionModal = () => {
    setActionModal(false);
  };
  const openCreateCastModal = () => {
    setIsOpen(true);
  };
  const closeCreateCastModal = () => {
    setIsOpen(false);
  };

  const afterCreateCastModalClose = () => {};
  const setActiveUsers = async (event: React.FormEvent) => {
    event.preventDefault();
    const users = await listUsers("ricsun");
    console.log(users);
  };
  const onFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const name = newCastName;
    const domain = customUri;
    const compactClientName = name.split(" ").join("").toLowerCase();
    const clientAdminUserEmail = `buri+${compactClientName}@buri.io`;
    const clientAdminUserPassword = `${compactClientName}admin${generatePassword()}`;
    let customDomain = [];
    //Create new application
    console.log("Creating Application");
    if (domain) {
      customDomain.push(domain);
    }
    const application: any = await createNewApplication(name, customDomain);
    const applicationUuid = application.id;
    const clientId = application.client_id;
    //Create groups
    let parentGroup = {
      id: application.default_group_id,
    };
    console.log("Creating Cast");
    let cast: any = await createCast(
      {
        title: `${name}`,
        description: "",
        clientId,
        groupId: parentGroup.id,
      },
      compactClientName
    );
    //Create and verify domains
    await createAndVerifyCastDomains(
      { castId: cast.id },
      compactClientName,
      domain
    );
    let learnScreen: any = await createScreen({
      castId: cast.id,
      screen: { title: "Learn", description: "Learn", slug: "learn" },
    });
    await createBlock({
      castId: cast.id,
      screenId: learnScreen.id,
      block: {
        order: 15,
        type: "carousel",
        properties: {
          items: [
            {
              image: process.env.REACT_APP_CLIENT_BANNER,
              src: process.env.REACT_APP_CLIENT_BANNER,
              uri: process.env.REACT_APP_CLIENT_BANNER,
            },
          ],
        },
      },
    });
    let navigationScreen: any = await createScreen({
      castId: cast.id,
      screen: {
        title: ".Navigation",
        description: "navigation placeholder",
        slug: "navigation",
      },
    });
    let navigationBlock: any = await createBlock({
      castId: cast.id,
      screenId: navigationScreen.id,
      block: {
        order: 10,
        type: "navigation",
        properties: {
          classes: {
            links: process.env.REACT_APP_NAVBAR_LINKS,
            "links-active": process.env.REACT_APP_NAVBAR_LINKS_ACTIVE,
            navbar: process.env.REACT_APP_NAVBAR,
          },
          items: [
            {
              icon: {
                material: "school",
                mdi: "school",
                sf_symbols: "graduationcap.fill",
              },
              screen_id: learnScreen.id,
              title: "Learn",
            },
          ],
        },
      },
    });
    cast.properties.initial_block_id = navigationBlock.id;
    console.log("Updating Cast");
    await updateCast(cast);
    //Create Default Roles
    console.log("Creating Default Roles");
    let adminRole: any = await createRole(
      {
        name: "Admin (All permissions)",
        description:
          "This user has full access to the administrator dashboard. They have the ability to modify users, assign roles and permissions, create resource collections and courses in the Cast LMS.",
        permissionNames: [
          "read:dashboard:all",

          "create:roles",
          "read:roles",
          "update:roles",
          "delete:roles",
          "create:permissions",
          "read:permissions",
          "update:permissions",
          "delete:permissions",

          "create:users",
          `read:users:group=${parentGroup.id}`,
          `update:users:group=${parentGroup.id}`,
          `read:groups:${parentGroup.id}`,
          "create:groups",
          `update:groups:${parentGroup.id}`,
          `read:groups:parent=${parentGroup.id}`,
          `update:groups:parent=${parentGroup.id}`,
          `delete:groups:parent=${parentGroup.id}`,

          `create:collections`,
          `read:collections:all`,
          `update:collections:all`,
          `delete:collections:all`,

          `create:resources`,
          `read:resources:all`,
          `update:resources:all`,
          `delete:resources:all`,

          `create:exams`,
          `read:exams:all`,
          `update:exams:all`,

          `create:courses:all`,
          `read:courses:all`,
          `update:courses:all`,
          `delete:courses:all`,

          `create:schedules:all`,
          `read:schedules:all`,
          `update:schedules:all`,
          `delete:schedules:all`,

          `upload:admin`,
        ],
      },
      applicationUuid
    );
    let courseAdminRole: any = await createRole(
      {
        name: "Course Admin (Read permissions only)",
        description:
          "This user can oversee the list of learners and their progress within the course. They are also given learner access to the course/s through the Cast.",
        permissionNames: [
          "read:dashboard:all",

          `read:users:group=${parentGroup.id}`,
          `read:groups:${parentGroup.id}`,
          `read:groups:parent=${parentGroup.id}`,

          `read:collections:all`,

          `read:resources:all`,

          `read:exams:all`,

          `read:courses:all`,

          `read:schedules:all`,
        ],
      },
      applicationUuid
    );
    let courseCreatorRole: any = await createRole(
      {
        name: "Course Creators (Full resource permissions)",
        description:
          "This user has the ability to modify resources within a course. They can create new collections, resources, and edit the contents of a course. They are also given learner access to the courses through the Cast.",
        permissionNames: [
          "read:dashboard:all",

          `read:users:group=${parentGroup.id}`,
          `read:groups:${parentGroup.id}`,
          `read:groups:parent=${parentGroup.id}`,

          `create:collections`,
          `read:collections:all`,
          `update:collections:all`,
          `delete:collections:all`,

          `create:resources`,
          `read:resources:all`,
          `update:resources:all`,
          `delete:resources:all`,

          `create:exams`,
          `read:exams:all`,
          `update:exams:all`,

          `create:courses:all`,
          `read:courses:all`,
          `update:courses:all`,
          `delete:courses:all`,

          `create:schedules:all`,
          `read:schedules:all`,
          `update:schedules:all`,
          `delete:schedules:all`,

          `upload:admin`,
        ],
      },
      applicationUuid
    );
    let userManagersRole: any = await createRole(
      {
        name: "User Managers (Full user accounts permissions)",
        description:
          "This user has the ability to modify users and their permissions within the Cast. They can enroll users to courses and arrange them in groups. They can also delegate the users' access permissions in the Cast LMS.",
        permissionNames: [
          "read:dashboard:all",

          "create:roles",
          "read:roles",
          "update:roles",
          "delete:roles",
          "create:permissions",
          "read:permissions",
          "update:permissions",
          "delete:permissions",

          "create:users",
          `read:users:group=${parentGroup.id}`,
          `update:users:group=${parentGroup.id}`,
          `read:groups:${parentGroup.id}`,
          "create:groups",
          `update:groups:${parentGroup.id}`,
          `read:groups:parent=${parentGroup.id}`,
          `update:groups:parent=${parentGroup.id}`,
          `delete:groups:parent=${parentGroup.id}`,

          `read:collections:all`,

          `read:resources:all`,

          `read:courses:all`,

          `create:schedules:all`,
          `read:schedules:all`,
          `update:schedules:all`,
          `delete:schedules:all`,
          `update:schedules:all`,
          `delete:schedules:all`,
        ],
      },
      applicationUuid
    );
    let learnerRole: any = await createRole(
      {
        name: "Learners (No dashboard access)",
        description:
          "This user has the ability to view and access the resources in a course they are enrolled in.",
        permissionNames: [
          "read:collections:all",
          "read:resources:all",
          `upload:student`,
        ],
      },
      applicationUuid
    );
    //Create Groups with Default Role
    console.log("Creating Administrators Group");
    let adminGroup: any = await createGroup({
      name: `Administrators`,
      parentId: parentGroup.id,
      roleIds: [adminRole.id],
    });
    console.log("Creating Course Admin Group");
    let courseAdminGroup: any = await createGroup({
      name: `Course Admin`,
      parentId: parentGroup.id,
      roleIds: [courseAdminRole.id],
    });
    console.log("Creating Course Creators Group");
    let courseCreatorGroup: any = await createGroup({
      name: `Course Creators`,
      parentId: parentGroup.id,
      roleIds: [courseCreatorRole.id],
    });
    console.log("Creating User Managers Group");
    let userManagerGroup: any = await createGroup({
      name: `User Managers`,
      parentId: parentGroup.id,
      roleIds: [userManagersRole.id],
    });
    console.log("Creating Learners Group");
    let learnerGroup: any = await createGroup({
      name: `Learners`,
      parentId: parentGroup.id,
      roleIds: [learnerRole.id],
    });
    //Create Admin User
    let checkEmail: any = await listUsers(clientAdminUserEmail);
    if (checkEmail.count > 0) {
      console.log("Adding Existing User as Admin");
      let uuid = "";
      let groupIds: string[] = [];
      checkEmail.users.map((user: any) => {
        if (user.email === clientAdminUserEmail) {
          uuid = user.uuid;
          groupIds = user.group_ids;
        }
      });
      await updateUser(uuid, groupIds, parentGroup.id, adminGroup.id);
    } else {
      console.log("Creating Admin User Buri");
      await createUser({
        firstName: "Buri",
        lastName: name,
        email: clientAdminUserEmail,
        password: clientAdminUserPassword,
        groupIds: [parentGroup.id, adminGroup.id],
      });
    }
    console.log("Done");
    console.log(`Dashboard: ${compactClientName}.castdashboard.com`);
    console.log(`Learner: ${compactClientName}.castlms.com`);
    console.log(`Admin Acccount: ${clientAdminUserEmail}`);
    console.log(`Admin Password: ${clientAdminUserPassword}`);
    navigate("/");
  };

  return (
    <div>
      <NavBarComponent></NavBarComponent>

      <TitleHeaderComponent title="Cast"></TitleHeaderComponent>

      <MainBodyLayoutComponent
        before={<h2 className="form-heading">Your Casts</h2>}
      >
        <button className="btn btn-primary" onClick={openCreateCastModal}>
          <Icon name="add_circle"></Icon>&nbsp;CREATE CAST
        </button>

        {/* <input
          type="text"
          className="form-control my-5"
          placeholder="Separate keywords with (,)"
        /> */}

        <div className="my-10">
          <ul>
            {isLoading ? (
              <span>Loading...</span>
            ) : !casts ? (
              <span>Nothing found.</span>
            ) : (
              casts.map((cast: any, i: number) => (
                <li className="hover:bg-gray-50" key={cast.id}>
                  <div className="flex items-center py-2 border-b ">
                    <img
                      className="w-10 h-10 object-contain border mx-2"
                      src={
                        cast.properties?.cast_icon?.web?.favicon ||
                        "https://buriblob.sgp1.cdn.digitaloceanspaces.com/cast%20logo%20purple.png"
                      }
                    />

                    <div className=" flex-1">
                      <div className="font-bold mb-1">{cast.title}</div>

                      {/* <span className="bg-secondary-500 text-white rounded-full text-xs whitespace-nowrap px-2 py-0.5">
                            Admin (ALL permissions)
                          </span> */}
                    </div>

                    {(cast.properties?.default_domain?.dashboard ||
                      cast.properties?.default_domain?.learner) && (
                      <div className="mx-10 flex-1">
                        {cast.properties?.default_domain?.dashboard && (
                          <div>
                            Dashboard: &nbsp;
                            <a
                              className="text-secondary-600"
                              href={
                                "https://" +
                                cast.properties?.default_domain?.dashboard
                              }
                            >
                              {cast.properties?.default_domain?.dashboard}
                            </a>
                          </div>
                        )}

                        {cast.properties?.default_domain?.learner && (
                          <div>
                            Learner: &nbsp;
                            <a
                              className="text-secondary-600"
                              href={
                                "https://" +
                                cast.properties?.default_domain?.learner
                              }
                            >
                              {cast.properties?.default_domain?.learner}
                            </a>
                          </div>
                        )}
                      </div>
                    )}

                    {/* <div className="mx-2 px-2 py-1 bg-secondary-100 rounded">
                          <div className="text-secondary-400 uppercase text-xs font-semibold">
                            Total Cast Users
                          </div>

                          <div className="text-secondary-700 uppercase text-lg  font-bold">
                            {(900000000).toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                          </div>
                        </div> */}

                    {activeUsersQueries[i]?.isSuccess && (
                      <div className="mx-2 px-2 py-1 bg-secondary-100 rounded">
                        <div className="text-secondary-400 uppercase text-xs font-semibold whitespace-nowrap">
                          Active Users for this month
                        </div>

                        <div className="flex justify-between space-x-2 text-secondary-700 uppercase text-lg font-bold">
                          <span>
                            {activeUsersQueries[i].data.count.toLocaleString(
                              navigator.language,
                              {
                                minimumFractionDigits: 0,
                              }
                            )}
                            {/* &nbsp;/&nbsp;
                              {(cast.activeUsers.limit).toLocaleString(navigator.language, {
                                minimumFractionDigits: 0,
                              })} */}
                          </span>
                          <button onClick={openActionModal}>
                            <Icon name="edit"></Icon>
                          </button>
                        </div>
                      </div>
                    )}

                    {/* <div className="mx-2 px-2 py-1 bg-secondary-100 rounded">
                          <div className="text-secondary-400 uppercase text-xs font-semibold">
                            Active Users Limit
                          </div>

                          <div className="text-secondary-700 uppercase text-lg  font-bold">
                            {(900000000).toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                          </div>
                        </div> */}

                    {/* <button className="p-1 mx-2" onClick={openActionModal}>
                          <Icon name="more_vert"></Icon>
                        </button> */}
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      </MainBodyLayoutComponent>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterCreateCastModalClose}
        onRequestClose={closeCreateCastModal}
        style={createModalStyle}
      >
        <div className="flex flex-col justify-between h-full">
          <section className="bg-primary-700 flex items-center justify-center flex-shrink-0 p-10">
            <img
              src={LoginBanner}
              className="object-contain max-h-[20vh]"
              alt=""
            />
          </section>

          <section className="text-center p-4">
            <h2 className=" font-bold text-2xl py-5">Welcome to Cast!</h2>

            <p className="">
              Insert catch phrase/sales pitch na relevant sa learning for
              businesses here
            </p>

            <div className="h-10"> </div>
          </section>

          <section className="text-center p-4">
            <form onSubmit={onFormSubmit}>
              <label className="font-bold " htmlFor="castname">
                What would you like to name your Cast?
              </label>

              <input
                id="castname"
                value={newCastName}
                onChange={(e) => setNewCastName(e.target.value)}
                placeholder="Your Cast name here"
                type="text"
                className="form-control mb-5 mt-2"
              />
              <label className="font-bold " htmlFor="custom">
                Do you have a custom URI? (Optional)
              </label>
              <input
                id="custom"
                value={customUri}
                onChange={(e) => setCustomUri(e.target.value)}
                placeholder="Your custom URI here"
                type="text"
                className="form-control mb-5 mt-2"
              />
              <button type="submit" className="btn btn-primary">
                Done
              </button>
            </form>
          </section>
        </div>
      </Modal>
      <Modal
        isOpen={actionModal}
        onAfterOpen={afterCreateCastModalClose}
        onRequestClose={closeActionModal}
        style={actionModalStyle}
      >
        <form className="p-4" onSubmit={setActiveUsers}>
          <label className="font-bold " htmlFor="limit">
            Set Active Users Limit
          </label>
          <input
            id="limit"
            placeholder="Set Active Users Limit Here"
            type="number"
            className="form-control mb-5 mt-2"
          />
          <div className="flex justify-center items-center w-full">
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default CastListPage;
