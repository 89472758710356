import { Link } from "react-router-dom";
import DefaultLogo from "../../assets/default-logo.png";

// Temporary Hardcode to DICT
export const CLIENT_ID =
  process.env.REACT_APP_CLIENT_ID ||
  "408169922124882d2155d714ec5728f50ae3cd4172514924b9563398cc7ea144";

function LoginPage() {
  const PASS_URI =
    process.env.REACT_APP_PASS_URI || "https://staging.pass.buri.dev";

  const gotoLoginPage = () => {
    window.location.href = `${PASS_URI}/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${window.location.origin}/auth/callback&response_type=code&scope=openid+profile+email`;
  };

  return (
    <div className="h-100 flex flex-col items-center justify-center absolute inset-0">
      <img src={DefaultLogo} alt="" className="my-5" />
      <button onClick={gotoLoginPage} className="btn btn-primary w-1/2">
        Sign in with Buri
      </button>
    </div>
  );
}

export default LoginPage;
