import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import Icon from "../../components/Icon/Icon";
import MainBodyLayoutComponent from "../../components/MainBodyLayoutComponent/MainBodyLayoutComponent";
import NavBarComponent from "../../components/NavBarComponent/NavBarComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
const ShareableScreens = () => {
  const [casts, setCasts] = useState<any[]>([]);
  useEffect(() => {
    let mockCasts = [...Array(5)].map((item, index) => {
      return {
        data: {
          screens: [
            {
              name: "Wikahon",
              id: 1,
              icon: "import_contacts",
              img: "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cmFuZG9tfGVufDB8fDB8fA%3D%3D&w=1000&q=80",
              selected: true,
            },
            {
              name: "Lawas",
              id: 2,
              icon: "school",
              img: "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cmFuZG9tfGVufDB8fDB8fA%3D%3D&w=1000&q=80",
              selected: false,
            },
          ],
          attributes: {
            auth: {
              clientId:
                "3dfd73be42760397af42e5a56fb87331690f89eb9201551184f538063938a912",
              url: "https://staging.pass.buri.dev/oauth/authorize?group_id=538f7cf7-c873-4e32-8a93-74c10a7d034e",
            },
            castIcon: {
              web: {
                favicon:
                  "https://d1g96a0e9vp024.cloudfront.net/sample/16a25c34-1440-45ab-a371-1e6762c89e58",
                hero: null,
                nav: "https://d1g96a0e9vp024.cloudfront.net/sample/0b0d427b-3df7-49f3-9f99-91e9d2b9542e",
              },
            },
            title: "Buri Technologies",
          },
          id: index,
        },
      };
    });

    setCasts(mockCasts);
  }, []);
  return (
    <div>
      <NavBarComponent />
      <TitleHeaderComponent title="Cast" />
      <MainBodyLayoutComponent before={<h2 className="form-heading">Casts</h2>}>
        <h2 className="font-bold text-2xl mb-4">Shareable Screens</h2>
        <input
          className="form-control mb-4"
          placeholder="Separate keywords with (,)"
        />
        <div className="flex justify-end mb-4">pagination component here</div>

        <ul>
          {casts.map((cast) => (
            <li className="" key={cast.data.id}>
              <div className="flex mb-2 items-center">
                <img
                  className="h-10 w-10 mr-2 border rounded"
                  src="https://play-lh.googleusercontent.com/xv-yGYix4fwEZDg-NJchtW5RLcSn93MWnTn_Bhoeu29pTTRynGaHqEA0C0M2sbJ8EyQ=w240-h480-rw"
                />
                <div className="font-bold text-gray-500 text-md">BuriBooks</div>
              </div>
              {cast.data.screens.map((screen: any) => (
                <div className="flex items-center" key={screen.id}>
                  <Checkbox checkClass="text-primary-600" uncheckClass="" />
                  <img
                    src={screen.img}
                    className="h-24 w-24 border rounded mx-2"
                  />
                  <div className="font-bold flex">
                    <div className="text-gray-400 mr-1">
                      <Icon name={screen.icon} />
                    </div>
                    {screen.name}
                  </div>
                </div>
              ))}
              <hr className="my-4" />
            </li>
          ))}
        </ul>

        <div className="flex justify-end">
          <Button
            textColor="text-white"
            btnColor="bg-primary-600"
            type="button"
            text="done"
          />
        </div>
      </MainBodyLayoutComponent>
    </div>
  );
};

export default ShareableScreens;
