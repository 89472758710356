function MainBodyLayoutComponent(props: { children?: any; before?: any }) {
  return (
    <div>
      <div className="mx-10 my-5">{props.before}</div>
      <div className="mx-10 my-5 p-5 bg-white">{props.children}</div>
    </div>
  );
}

export default MainBodyLayoutComponent;
