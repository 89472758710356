import { useState } from "react";
import Icon from "../Icon/Icon";

const Checkbox = (props: { checkClass: string; uncheckClass: string }) => {
  const checkIcon = "check_box";
  const uncheckIcon = "check_box_outline_blank";
  const [checked, setChecked] = useState(false);
  const check = (e: any) => {
    setChecked(e.target.checked);
  };

  return (
    <label>
      <input type="checkbox" className="hidden" onChange={(e) => check(e)} />
      <div className={checked ? props.checkClass : props.uncheckClass}>
        <Icon name={checked ? checkIcon : uncheckIcon} />
      </div>
    </label>
  );
};

export default Checkbox;
