import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DefaultLogo from "../../assets/default-logo.png";

function TitleHeaderComponent(props: { title?: string }) {
  return (
    <div className="w-full ">
      <div className="bg-secondary-600 text-white p-6">
        <h2 className="font-bold text-2xl">{props.title}</h2>
      </div>

      <svg
        width="100%"
        height="40"
        viewBox="0 0 1140 40"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1140 0L1140 36.2214L0 23.2997L0 0L1140 0" fill="#BFE6FE" />
        <path d="M0 40L1140 9.08196L1140 0L0 0L0 40Z" fill="#0284C7" />
      </svg>
    </div>
  );
}

export default TitleHeaderComponent;
