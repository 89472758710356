import axios from "axios";

export const RestService = axios.create({
  baseURL: "/api/",
  timeout: 10000,
  withCredentials: true,
});

export const buriCastRequest = axios.create({
  baseURL: process.env.REACT_APP_CAST_SERVICE_URI,
  timeout: 60000,
  withCredentials: true,
  maxRedirects: 5,
});

export const buriPassRequest = axios.create({
  baseURL: process.env.REACT_APP_PASS_SERVICE_URI,
  timeout: 60000,
  withCredentials: true,
  maxRedirects: 5,
  headers: {
    'Accept': 'application/json'
  }
});
