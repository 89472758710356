import { useEffect, useState } from "react";
import Icon from "../../components/Icon/Icon";
import MainBodyLayoutComponent from "../../components/MainBodyLayoutComponent/MainBodyLayoutComponent";
import NavBarComponent from "../../components/NavBarComponent/NavBarComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";

const Screens = () => {
  const [screens, setScreens] = useState<any[]>([]);
  useEffect(() => {
    let mockScreens = [...Array(5)].map((item, index) => {
      return {
        cast_id: 4,
        description: "test",
        id: index,
        slug: "search",
        title: "Search",
        shareable: true,
        navbar: true,
        icon: "school",
        image:
          "https://media.istockphoto.com/vectors/sample-stamp-sample-square-grunge-sign-sample-vector-id1172218730?k=20&m=1172218730&s=612x612&w=0&h=0iTFUtb2WJtRAfg4Lbx7oh2lfVtH5pBsQAMxhCWKZH0=",
      };
    });
    setScreens(mockScreens);
  }, []);
  return (
    <div>
      <NavBarComponent />
      <TitleHeaderComponent title="Cast" />
      <MainBodyLayoutComponent before={<h2 className="form-heading">Casts</h2>}>
        <h2 className="font-bold text-2xl mb-4">Screens</h2>
        <button className="btn btn-primary mb-4">
          <Icon name="add_circle" />
          &nbsp;add screen
        </button>
        <ul className="">
          {screens.map((screen) => (
            <li key={screen.id} className="w-full">
              <div className="md:flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={screen.image}
                    className="h-24 w-28 border rounded mx-2 object-contain"
                  />
                  <div className="overflow-hidden">
                    <div className="flex">
                      <div className="text-gray-400 mr-1">
                        <Icon name={screen.icon} />
                      </div>
                      <div className="font-bold">{screen.title}</div>
                    </div>
                    {screen.shareable ? (
                      <div className="text-gray-400 text-sm mt-4 flex items-center">
                        <Icon name="link" />
                        This screen is shareable
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center overflow-hidden mx-2">
                  {screen.navbar ? (
                    <span className="border rounded-full text-xs text-primary-600 bg-primary-200 px-2 py-1">
                      PRESENT ON NAVBAR
                    </span>
                  ) : null}

                  <button className="p-1 mx-2">
                    <Icon name="more_vert"></Icon>
                  </button>
                </div>
              </div>
              <hr className="my-4" />
            </li>
          ))}
        </ul>
      </MainBodyLayoutComponent>
    </div>
  );
};

export default Screens;
