import Modal from "react-modal";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

import "./App.css";
import AuthCallback from "./pages/AuthCallback/AuthCallback";
import CastEditor from "./pages/CastEditor/CastEditor";
import CastListPage from "./pages/CastListPage/CastListPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import Screens from "./pages/Screens/Screens";
import ShareableScreens from "./pages/ShareableScreens/ShareableScreens";

Modal.setAppElement("#root");

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={({ error, resetErrorBoundary }) => {
            console.log((error as any)?.response?.status);
            if ((error as any)?.response?.status === 401) {
              resetErrorBoundary();

              return <Navigate to="/login"></Navigate>;
            }

            return (
              <div className="h-100 flex flex-col items-center justify-center absolute inset-0">
                Error
              </div>
            );
          }}
        >
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          ></link>

          <Routes>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/auth/callback" element={<AuthCallback />}></Route>
            <Route path="/" element={<CastListPage />}></Route>
            <Route path="/casteditor" element={<CastEditor />}></Route>
            <Route path="/logout" element={<LogoutPage />}></Route>
            <Route path="/screens" element={<ShareableScreens />}></Route>
            <Route path="/add-screen" element={<Screens />}></Route>
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
