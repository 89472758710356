import Icon from "../Icon/Icon";

const Button = (props: {
  textColor: string;
  btnColor: string;
  type: "button" | "submit" | "reset" | undefined;
  text?: string;
  icon?: string;
  action?: any;
}) => {
  let btnStyle = `btn uppercase ${props.btnColor} ${props.textColor}`;
  return (
    <button type={props.type} className={btnStyle} onClick={props.action}>
      <Icon name={props.icon as string} />
      {props.icon && props.text ? <>&nbsp;</> : null}
      {props.text}
    </button>
  );
};
export default Button;
