import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DefaultLogo from "../../assets/default-logo.png";

function NavBarComponent() {
  return (
    <nav className="flex justify-between items-center px-6 py-2">
      <img src={DefaultLogo} alt="Cast Logo" className="h-10 object-contain" />

      <Link to="/logout">
        <i className="material-icons">exit_to_app</i>
      </Link>
    </nav>
  );
}

export default NavBarComponent;
